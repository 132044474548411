import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkCookieSearchData } from '../actions/baseActions';
import Hotels from '../app/components/Hotels';

const Home: NextPage = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		checkCookieSearchData()(dispatch);
	}, []);

	return (
		<div>
			<NextSeo
				title={t('metadata.hotels.title')}
				description={t('metadata.hotels.description')}
				canonical={process.env.NEXT_PUBLIC_SITE_URL}
				openGraph={{
					url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
					title: t('metadata.hotels.title'),
					description: t('metadata.hotels.description'),
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			<Hotels
				slug=""
				query={
					// @ts-ignore
					props.query
				}
			/>
		</div>
	);
};
Home.getInitialProps = async (ctx) => {
	const query = ctx.query;
	return { query };
};
export default Home;
